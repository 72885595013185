import axiosInstance from "./axiosInstance";

const api = {
  getAllArticles: (data) => axiosInstance.get("/getArticles", { params: data }),
  getCategoryFilteredArticle: (data) =>
    axiosInstance.get("/getCategoryFilteredArticles", { params: data }),
  getTagFilteredArticle: (data) =>
    axiosInstance.get("/getCategoryFilteredTag", { params: data }),
  getArticle: (id) => axiosInstance.get("/getArticle/" + id),
  getAllTags: () => axiosInstance.get("/getAllTags"),
  postComment: (data) => axiosInstance.post("/postComments", data),
};
const postApi = {};
export default api;
