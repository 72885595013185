import React, { useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Avatar,
  SwipeableDrawer,
  ToggleButton,
  Switch,
  SwitchProps,
} from "@mui/material";

import { AccountCircle, ChevronRight, ChevronLeft } from "@mui/icons-material";
import { styled, useTheme } from "@mui/material/styles";
import { AppContext } from "../App";
import { useNavigate } from "react-router-dom";
// const IOSSwitch = styled((props: SwitchProps) => (
//   <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//   width: 42,
//   height: 26,
//   padding: 0,
//   "& .MuiSwitch-switchBase": {
//     padding: 0,
//     margin: 2,
//     transitionDuration: "300ms",
//     "&.Mui-checked": {
//       transform: "translateX(16px)",
//       color: "#fff",
//       "& + .MuiSwitch-track": {
//         backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
//         opacity: 1,
//         border: 0,
//       },
//       "&.Mui-disabled + .MuiSwitch-track": {
//         opacity: 0.5,
//       },
//     },
//     "&.Mui-focusVisible .MuiSwitch-thumb": {
//       color: "#33cf4d",
//       border: "6px solid #fff",
//     },
//     "&.Mui-disabled .MuiSwitch-thumb": {
//       color:
//         theme.palette.mode === "light"
//           ? theme.palette.grey[100]
//           : theme.palette.grey[600],
//     },
//     "&.Mui-disabled + .MuiSwitch-track": {
//       opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxSizing: "border-box",
//     width: 22,
//     height: 22,
//   },
//   "& .MuiSwitch-track": {
//     borderRadius: 26 / 2,
//     backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
//     opacity: 1,
//     transition: theme.transitions.create(["background-color"], {
//       duration: 500,
//     }),
//   },
// }));

const pages = ["Products", "Pricing", "Blog"];
const drawerWidth = 240;
export default function Header({ scrollToHome, openMenu }) {
  const { defaultContext, setContext } = useContext(AppContext);
  const navigate = useNavigate();
  const MUiAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const menuClick = function (id, type) {
    //alert("ok");
    if (type === "all") {
      navigate("/");
    } else {
      navigate(`/?id=${id}&type=${type}`);
    }
  };
  const [switchVal, setSwitchVal] = useState(
    defaultContext.theme === "theme-light"
  );
  const switchTheme = () => {
    let theme =
      defaultContext.theme === "theme-light" ? "theme-dark" : "theme-light";
    document.body.classList.remove(defaultContext.theme);
    defaultContext.theme = theme;
    setSwitchVal(defaultContext.theme === "theme-light");
    document.body.classList.add(defaultContext.theme);
    setContext(defaultContext);
  };
  useEffect(function () {
    document.body.classList.remove();
    document.body.classList.add(defaultContext.theme);
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar className="appBarStyle" position="fixed">
        <Container maxWidth="xl">
          {/* <div>
            <div style={{ float: "right" }}>
              <span>Light Theme</span>
              <IOSSwitch checked={switchVal} onChange={switchTheme}></IOSSwitch>
            </div>
          </div> */}
          <Toolbar style={{ justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              <MenuIcon
                // sx={{ mr: 4, display: { xs: "flex", md: "none" } }}
                style={{ marginTop: 5, cursor: "pointer" }}
                onClick={openMenu}
              ></MenuIcon>
              <Typography
                variant="h6"
                className="appBarHeader"
                style={{ cursor: "pointer" }}
                onClick={() => menuClick(1, "all")}
                noWrap
                component="div"
                sx={{ mr: 4, display: { xs: "none", md: "flex" } }}
              >
                தமிழன் பிரபாகரன்
              </Typography>
            </div>
            <Typography
              variant="h6"
              style={{ cursor: "pointer" }}
              className="appBarHeader"
              noWrap
              component="div"
              onClick={() => menuClick(1, "category")}
              sx={{ mr: 4, display: { xs: "none", md: "flex" } }}
            >
              கட்டுரைகள்
            </Typography>
            <Typography
              variant="h6"
              className="appBarHeader"
              style={{ cursor: "pointer" }}
              noWrap
              component="div"
              onClick={() => menuClick(2, "category")}
              sx={{ mr: 4, display: { xs: "none", md: "flex" } }}
            >
              சிறுகதைகள்
            </Typography>
            <Typography
              variant="h6"
              className="appBarHeader"
              style={{ cursor: "pointer" }}
              noWrap
              onClick={() => menuClick(3, "category")}
              component="div"
              sx={{ mr: 4, display: { xs: "none", md: "flex" } }}
            >
              கவிதைகள்
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}
