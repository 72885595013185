import {
  Box,
  Modal,
  Button,
  useMediaQuery,
  ListItem,
  List,
  Divider,
} from "@mui/material";
import React from "react";
import Category from "./Category";
import { useTheme } from "@emotion/react";

export default function SideMenu(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const style = {
    position: "absolute",
    top: "0%",
    left: "0%",
    backgroundColor: "rgb(77, 75, 75)",
    borderRight: "1px solid #cea75c",
    //transform: "translate(-50%, -50%)",
    width: isMobile ? "50vw" : "30vw",
    height: "100vh",
    //   border: "2px solid #000",
    //   boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  const mobstyle = {
    position: "absolute",
    top: "0%",
    left: "0%",
    backgroundColor: "rgb(77, 75, 75)",
    borderRight: "1px solid #cea75c",
    //transform: "translate(-50%, -50%)",
    width: "50vw",
    height: "100vh",
    //   border: "2px solid #000",
    //   boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Modal
        open={props.isSideMenuOpen}
        onClose={props.handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <Category close={props.handleClose}></Category>
        </Box>
      </Modal>
    </div>
  );
}
