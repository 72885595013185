import {
  Button,
  CircularProgress,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import api from "../Api/api";
import { format } from "date-fns";

export default function PostComment(props) {
  const [username, setusername] = useState("");
  const [comment, setComment] = useState("");
  const [isPosting, setIsPosting] = useState(false);
  const post = async () => {
    setIsPosting(true);
    var data = {
      comment: comment,
      username: username,
      id: props.id,
    };
    var result = await api.postComment(data);
    if (result.data == "success") {
      // var comments = props.Comment.push({
      //   name: username,
      //   Comment: comment,
      //   PostedOn: new Date(),
      // });
      const newComment = {
        username: username,
        Comment: comment,
        PostedOn: new Date(),
      };

      // Use the functional update form of the setter to ensure React re-renders
      props.setComments((prevComments) => [...prevComments, newComment]);
      setIsPosting(false);
    }
  };

  return (
    <div
      style={{
        marginTop: 10,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div></div>
      <div style={{ width: "80%" }}>
        {isPosting && (
          <div style={{ height: "auto" }}>
            <CircularProgress />
          </div>
        )}
        <div>
          <span className="header2">Post your comments</span>
        </div>
        <div style={{ marginTop: 10 }}>
          <TextField
            placeholder="Name"
            value={username}
            onChange={(event) => {
              setusername(event.target.value);
            }}
            style={{ width: "80%", backgroundColor: "white" }}
          ></TextField>
        </div>
        <div style={{ marginTop: 10 }}>
          <TextareaAutosize
            placeholder="Comments"
            value={comment}
            onChange={(event) => {
              setComment(event.target.value);
            }}
            style={{ width: "80%", backgroundColor: "white", height: 150 }}
          ></TextareaAutosize>
        </div>
        <div style={{ marginTop: 10 }}>
          <Button
            onClick={post}
            variant="contained"
            className="bgSecondaryColor"
            style={{ width: "80%" }}
          >
            Post
          </Button>
        </div>
      </div>

      <div></div>
    </div>
  );
}
