import { Route, Routes } from "react-router-dom";
import Home from "./screen/Home";
import Article from "./screen/Article";
export function BlogNavigation({ setRef }) {
  return (
    <Routes>
      <Route path="/" element={<Home setRef={setRef}></Home>}></Route>
      <Route path="/article" element={<Article></Article>}></Route>
    </Routes>
  );
}
