import React, { useEffect, useRef } from "react";
import Category from "../Components/Category";
import Content from "../Components/Content";
import Footer from "../Components/Footer";
import { Pagination } from "@mui/material";
import { useLocation } from "react-router-dom";

export default function Home({ setRef }) {
  const divRef = useRef(null);
  const { state } = useLocation();
  useEffect(function () {
    setRef(divRef);
  }, []);
  return (
    <div ref={divRef} className="homeContainer">
      <div className="contentContainer">
        <Content state={state}></Content>
      </div>
    </div>
  );
}
