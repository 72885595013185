import axios from "axios";
const isProduction = true;

const axiosInstance = axios.create({
  baseURL: isProduction
    ? "https://writerprabakaran.com/writerprabakaranapi/public"
    : "http://localhost/writerprabakaranapi/public",
  timeout: 100000,
  headers: { "Content-Type": "multipart/form-data" },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    // e.g., add authentication token
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    debugger;
    // Handle response errors
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
