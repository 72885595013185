import { CalendarMonth } from "@mui/icons-material";
import { Paper } from "@mui/material";
import React from "react";
import { format } from "date-fns";

export default function Comment(props) {
  var comment = props.comment;
  debugger;
  return (
    <div
      style={{
        borderTop: "1px solid rgb(249, 202, 110)",
      }}
    >
      <Paper elevation={2} className="commentContainer">
        <div style={{ marginLeft: 2 }}>
          <span className="commentUser">{comment.username}</span>
          <CalendarMonth
            style={{
              fontSize: 15,
              marginLeft: 10,
              verticalAlign: "middle",
            }}
          ></CalendarMonth>
          <span
            style={{
              fontSize: 15,
            }}
          >
            {format(new Date(comment.PostedOn), "dd-MM-yyyy")}
          </span>
        </div>
        <div></div>
        <div style={{ marginTop: 5, marginLeft: 15 }}>
          <span
            style={{
              fontSize: 18,
            }}
          >
            {comment.Comment}
          </span>
        </div>
      </Paper>
    </div>
  );
}
