import { createSlice } from "@reduxjs/toolkit";
export const UserSlice=createSlice({
    name:'user',
    initialState:{
        isLoggedIn:false,
        token:'',
        email:'',
        userName:'',
        userRole:'',
        mobileno:''
    },
    reducers:{
        UserLogin:(state,action)=>void(
            state.userName=action.payload.username,
            state.isLoggedIn=true,
            state.userRole=action.payload.role,
            state.token=action.payload.token,
            state.email=action.payload.email,
            state.mobileno=action.payload.mobileno
        ),
        Logout:(state)=>void(
            state.userName='',
            state.isLoggedIn=false,
            state.userRole=''
        )
    }
})

export const{UserLogin,Logout}=UserSlice.actions;
export default UserSlice.reducer;