import {
  CalendarMonth,
  ChatBubbleOutline,
  FavoriteBorderOutlined,
} from "@mui/icons-material";
import { Button, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../Api/api";

export default function ContentThumbnail({ content }) {
  const navigate = useNavigate();
  const [article, setArticle] = useState({});

  return (
    <div className="contentThumbnailContainer">
      <img className="thumbnailImage" src={content.thumbnail}></img>
      <div>
        <CalendarMonth style={{ verticalAlign: "bottom" }}></CalendarMonth>
        <span>{content.createdon}</span>
      </div>

      <div className="contentSummary">
        <div>
          <span className="header2">{content.title}</span>
        </div>
        <span>{content.content}</span>
      </div>
      <div>
        <Button
          onClick={() =>
            navigate(`/article?id=${content.id}&title=${content.url_title}`)
          }
          style={{ cursor: "pointer !important", width: "80%" }}
          className="bgSecondaryColor"
          variant="contained"
        >
          Read More.
        </Button>
      </div>
      <Divider className="contentThumbnailDivider"></Divider>
      <div className="thumbnailStatistic">
        <div>
          <span>{content.Views} Views</span>
        </div>

        <div>
          <ChatBubbleOutline
            style={{ verticalAlign: "bottom" }}
          ></ChatBubbleOutline>
          <span>{content.comments} Comments</span>
        </div>
      </div>
    </div>
  );
}
