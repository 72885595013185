import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import { Chip, Paper } from "@mui/material";
import DrawIcon from "@mui/icons-material/Draw";
import {
  CalendarMonth,
  ChatBubbleOutline,
  Visibility,
} from "@mui/icons-material";
import Comment from "../Components/Comment";
import PostComment from "../Components/PostComment";
import Footer from "../Components/Footer";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import api from "../Api/api";

export default function Article() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [comments, setComments] = useState([]);
  const [article, setArticle] = useState({});
  const [articleId, setArticleId] = useState("");
  const [searchParams] = useSearchParams();
  useEffect(() => {
    var getarticleContent = async function () {
      var id = searchParams.get("id");
      setArticleId(id);
      var articleContent = await api.getArticle(id);
      setArticle(articleContent.data.article[0]);
      setComments(articleContent.data.comments);
      // setArticle(articleContent.data);
    };
    getarticleContent();
    window.scrollTo(0, 0);
  }, []);
  const handleChipClick = (event) => {
    navigate(`/?id=${event.target.textContent}&type='tag'`);
  };
  return (
    <div>
      {/* <Header></Header> */}
      <div className="articleFooter" style={{ marginTop: 100 }}>
        <div></div>
        <div
          style={{
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <CalendarMonth style={{ verticalAlign: "bottom" }}></CalendarMonth>
            <span>{article.createdon}</span>
          </div>
          <div>
            <DrawIcon style={{ verticalAlign: "bottom" }}></DrawIcon>
            <span>தமிழன் பிரபாகரன்</span>
          </div>
        </div>
        <div></div>
      </div>
      <div className="articleContainer">
        <div></div>
        <Paper className="articleElevationBoxContainer" elevation={1}>
          <div style={{ textAlign: "center" }}>
            <span className="header2">{article.title}</span>
          </div>
          <div style={{ textAlign: "center" }}>
            <img
              style={{ height: 200, width: 300 }}
              src={article.thumbnail}
            ></img>
          </div>
          <div style={{ marginLeft: 5 }}>
            {article.content &&
              article.content.split("\n").map(function (item, index) {
                return <p>{item}</p>;
              })}
            {/* <p>{content.content}</p> */}
          </div>
        </Paper>
        <div></div>
      </div>
      <div className="articleFooter" style={{ marginTop: 2 }}>
        <div></div>
        <div
          style={{
            width: "80%",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <div>
            <ChatBubbleOutline
              style={{ verticalAlign: "bottom" }}
            ></ChatBubbleOutline>
            <span>{comments.length > 0 && comments.length} Comments</span>
          </div>
          <div>
            <Visibility style={{ verticalAlign: "bottom" }}></Visibility>
            <span>{article.Views} Views</span>
          </div>
        </div>
        <div></div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: 10,
          justifyContent: "space-between",
        }}
      >
        <div></div>
        <div style={{ width: "80%" }}>
          {article.tag &&
            article.tag.split(",").map(function (item, index) {
              return (
                <Chip
                  onClick={(item) => handleChipClick(item)}
                  style={{
                    background: "var(--secondary-color)",
                    marginRight: 5,
                    cursor: "pointer",
                  }}
                  label={item}
                />
              );
            })}
        </div>
        <div></div>
      </div>
      <div className="articleFooter" style={{ marginTop: 50 }}>
        <div></div>
        <div style={{ width: "80%", maxHeight: 300, overflowY: "auto" }}>
          <div>
            <span>Comments</span>
          </div>
          {comments.map(function (item, index) {
            return (
              <div>
                <Comment comment={item}></Comment>
                <div style={{ height: 20 }}></div>
              </div>
            );
          })}
        </div>
        <div></div>
      </div>
      <PostComment
        Comment={comments}
        setComments={setComments}
        id={articleId}
      ></PostComment>
    </div>
  );
}
