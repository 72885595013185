import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../Api/api";
import { useNavigate } from "react-router-dom";

export default function Category(props) {
  const [categories, setCategories] = useState([]);
  var navigate = useNavigate();
  useEffect(() => {
    const getTags = async function () {
      var tags = await api.getAllTags();
      setCategories(tags.data);
    };
    getTags();
  });
  const menuClick = function (id, type) {
    //alert("ok");
    props.close();
    if (type === "all") {
      navigate("/");
    } else {
      navigate(`/?id=${id}&type=${type}`);
    }
  };
  return (
    <div>
      <div
        style={{
          background: "#cea75c",
          textAlign: "center",
          borderRadius: 25,
        }}
      >
        <span>Category</span>
      </div>
      <div
        style={{
          height: "100vh",
          scrollbarColor: "#cea75c",
          width: "100%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <List>
          <ListItem components="a">
            <ListItemButton onClick={() => menuClick(1, "all")}>
              <ListItemText key={0} primary="அனைத்தும்"></ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem components="a">
            <ListItemButton onClick={() => menuClick(1, "category")}>
              <ListItemText key={1} primary="கட்டுரைகள்"></ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => menuClick(3, "category")}>
              <ListItemText key={3} primary="கவிதைகள்"></ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => menuClick(2, "category")}>
              <ListItemText key={2} primary="சிறுகதைகள்"></ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider></Divider>
          {categories.map((item, index) => {
            return (
              <ListItem>
                <ListItemButton onClick={() => menuClick(item.name, "tag")}>
                  <ListItemText primary={item.name}></ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </div>
    </div>
  );
}
