import React, {
  useEffect,
  useRef,
  useState,
  createContext,
  useContext,
} from "react";
import logo from "./logo.svg";
import { Counter } from "./features/counter/Counter";
import "./App.css";
import { useSelector } from "react-redux";
import Header from "./Components/Header";
import Banner from "./Components/Banner";
import Home from "./screen/Home";
import { BlogNavigation } from "./Navigation";
import { useLocation } from "react-router-dom";
import Footer from "./Components/Footer";
import SideMenu from "./Components/SideMenu";

export const AppContext = createContext();
function App() {
  const [defaultContext, setContext] = useState({
    theme: "theme-dark",
  });
  // const homeRef = useRef(null);
  const userDetails = useSelector((state) => state.user);
  const [isHome, setIsHome] = useState(true);
  const [isSideMenuOpen, setSideMenuOpen] = useState(false);
  const isLoggedIn = userDetails ? userDetails.isLoggedIn : false;
  const [currentPath, setCurrentPath] = useState("");
  const location = useLocation();
  const handleOpen = () => {
    setSideMenuOpen(true);
  };
  const handleClose = () => {
    setSideMenuOpen(false);
  };
  var context = useContext(AppContext);
  //setCurrentPath(location.pathname);
  const [homeR, setHomeRef] = useState(null);
  useEffect(
    function () {
      if (location.pathname === "/") {
        setIsHome(true);
      } else {
        setIsHome(false);
      }
    },
    [location]
  );
  const scrollToHome = () => {
    debugger;
    homeR.current.scrollIntoView();
  };
  return (
    <AppContext.Provider value={{ defaultContext, setContext }}>
      <div className="App">
        <SideMenu
          isSideMenuOpen={isSideMenuOpen}
          handleOpen={handleOpen}
          handleClose={handleClose}
        ></SideMenu>
        <Header openMenu={handleOpen} scrollToHome={scrollToHome}></Header>
        {isHome && <Banner></Banner>}
        <BlogNavigation setRef={setHomeRef}></BlogNavigation>
        <Footer></Footer>
      </div>
    </AppContext.Provider>
  );
}

export default App;
