import { configureStore,combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import UserReducer from "../Reducers/UserSlice";
import ComponentReduce from "../Reducers/ComponentStateSlice";

const persistConfig = {
    key: 'root',
    storage
};

const combinedReducers = combineReducers({
  user: UserReducer,
  componentState:ComponentReduce
});

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const Store= configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
})