import { createSlice } from "@reduxjs/toolkit";
export const ComponentState=createSlice({
    name:'ComponentState',
    initialState:{
        isDrawerOpen:false,
    },
    reducers:{
        UpdateComponentState:(state,action)=>void(
            state.isDrawerOpen=action.payload.isDrawerOpen
        )
    }
})

export const{UpdateComponentState}=ComponentState.actions;
export default ComponentState.reducer;