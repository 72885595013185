import {
  Divider,
  Grid,
  List,
  ListItem,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ContentThumbnail from "./ContentThumbnail";
import api from "../Api/api";
import { useSearchParams } from "react-router-dom";

export default function Content(props) {
  const pageSize = 9;
  const [searchParams] = useSearchParams();
  var [arcticles, setArticles] = useState([]);
  var reLoad = props.state ? [props.state.id] : [];
  var [count, setCount] = useState(0);
  var [sort, setSort] = useState(1);
  var id = searchParams.get("id");
  var type = searchParams.get("type");
  var fetchArticle = async function (sortValue, page) {
    var result = [];
    if (type === null || type === "" || type === "all") {
      result = await api.getAllArticles({
        pageSize: pageSize,
        page: page,
        sort: sortValue,
      });
    } else if (type === "category") {
      result = await api.getCategoryFilteredArticle({
        categoryid: id,
        pageSize: pageSize,
        page: page,
        sort: sortValue,
      });
      window.scrollTo(0, 700);
    } else {
      result = await api.getTagFilteredArticle({
        tag: id,
        pageSize: pageSize,
        page: page,
        sort: sortValue,
      });
      window.scrollTo(0, 700);
    }
    setCount(Math.floor(result.data.count[0]["Count"] / pageSize) + 1);
    setArticles(result.data.articles);

    //debugger;
  };
  useEffect(
    function () {
      fetchArticle(1, 1);
    },
    [id]
  );
  const handleSortChange = async (event) => {
    setSort(event.target.value);
    var sortValue = event.target.value;
    var type = "";
    if (props.state) {
      type = props.state.type;
    }
    fetchArticle(sortValue, 1);
  };
  const handlePageChange = (event, value) => {
    setSort(1);
    var type = "";
    if (props.state) {
      type = props.state.type;
    }
    fetchArticle(1, value);
    window.scrollTo(0, 700);
  };
  return (
    <div style={{ width: "100%", marginLeft: 20 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div></div>
        <div style={{ color: "white" }}>
          <Select
            value={sort}
            onChange={(event) => handleSortChange(event)}
            style={{ cursor: "pointer", color: "white" }}
          >
            <MenuItem value={1}>SortyBy - Date</MenuItem>
            <MenuItem value={2}>SortyBy - Views</MenuItem>
          </Select>
        </div>
      </div>
      <Grid container>
        {arcticles.map(function (item, index) {
          return (
            <Grid item xs={6} sm={4}>
              <ContentThumbnail content={item}></ContentThumbnail>
            </Grid>
          );
        })}
      </Grid>
      <div
        style={{
          display: "flex",
          marginTop: 20,
          justifyContent: "space-between",
        }}
      >
        <div></div>
        <Pagination
          count={count}
          onChange={handlePageChange}
          renderItem={(item) => (
            <PaginationItem
              components={{ previous: "prev", next: "next" }}
              {...item}
              sx={{
                color: "white", // Text color
                "&:hover": {
                  backgroundColor: "var(--secondary-color)", // Background color on hover
                },
                "&.Mui-selected": {
                  backgroundColor: "var(--secondary-color)", // Background color of the selected page
                  color: "white",
                },
                ".MuiPaginationItem-icon": {
                  color: "var(--secondary-color)", // Icon color, applies to previous and next icons
                },
              }}
            />
          )}
        />
        <div></div>
      </div>
    </div>
  );
}
