import { Facebook, Instagram, Twitter, YouTube } from "@mui/icons-material";
import React from "react";

export default function Footer() {
  const iconClick = (url) => {
    window.open(url, "_blank").focus();
  };
  return (
    <div className="footer">
      <div className="footerContainer">
        <Facebook
          onClick={() => iconClick("https://www.facebook.com/sunil.prabakar")}
          style={{ cursor: "pointer" }}
        ></Facebook>
        <Twitter
          onClick={() => iconClick("https://x.com/sunilprabakarc")}
          style={{ cursor: "pointer" }}
        ></Twitter>
        <Instagram
          onClick={() =>
            iconClick("https://www.instagram.com/tamizhan_prabakaran/")
          }
          style={{ cursor: "pointer" }}
        ></Instagram>
        <YouTube
          onClick={() =>
            iconClick(
              "https://www.youtube.com/channel/UCkPwMacMP8kUcR0YzCNlTvQ"
            )
          }
          style={{ cursor: "pointer" }}
        ></YouTube>
      </div>
    </div>
  );
}
